import React, { useEffect, useMemo, useRef } from 'react';
import { ColorOptions } from 'types/color.type';
import { ColorHelpers as GlobalColorHelpers } from 'helpers/colors.helpers';
import Translation from 'components/data/Translation';
import { ColorSettings, ColorSettingsPicker, DynamicLayerInput } from 'components/template-designer/types/dynamicLayer.type';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { ColorHelpers } from 'components/template-designer/helpers/color.helpers';
import { FieldSetDynamicColorList } from 'components/input/DynamicColorList';
import cloneDeep from 'helpers/cloneDeep';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import Tooltip from 'components/ui-components-v2/Tooltip';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import Switch from 'components/ui-components-v2/Switch';
import '../styles/main.scss';

enum ColorSelectionType {
    PredefinedColorList = 'predefinedColorList',
    FreeColorPicker = 'freeColorPicker'
}

interface Props {
    attribute: DynamicLayerInput['attribute'];
    canUseGradients?: boolean;
    canUseAlpha?: boolean;
    updateSettings?: InputAttribute<ColorSettings>['updateSettings'];
}

/**
 * ColorSettingsForm for setting up the color picker for various attributes.
 */
const ColorSettingsForm = ({ attribute, canUseGradients, canUseAlpha = true, updateSettings }: Props): JSX.Element | null => {
    const { settings, activeInput, activeInputLayer, updateInputSettings, updateActiveInput } = useDynamicLayers<ColorSettings>();
    const oldSettingsObject = useRef({});

    const templateValue = useMemo(
        () => activeInputLayer && DynamicLayerHelpers.getGeneralTemplateValue(attribute, settings, activeInputLayer),
        [activeInputLayer]
    );

    // Update the first color if the template color value changes.
    useEffect(() => {
        if (!activeInput || !activeInputLayer || settings.type !== 'color') return;
        if (updateSettings && settings.colors && attribute) {
            const newSettings = updateSettings(activeInputLayer, attribute, settings);
            updateActiveInput('settings', newSettings);
        }
    }, [templateValue]);

    const colorsWithGradients = useMemo(() => [templateValue, ...ColorHelpers.getBrandGuideColorsString(true)], []);
    const colorsWithoutGradients = useMemo(() => [templateValue, ...ColorHelpers.getBrandGuideColorsString(false)], []);

    const selectionTypes = {
        [ColorSelectionType.FreeColorPicker]: {
            ...settings,
            picker: ColorSettingsPicker.Picker
        },
        [ColorSelectionType.PredefinedColorList]: {
            colors: settings.useGradients ? colorsWithGradients : colorsWithoutGradients,
            ...settings,
            picker: ColorSettingsPicker.PredefinedCustom
        }
    };

    /**
     * get the color selection type based on what the settings look like.
     */
    const colorSelectionType: ColorSelectionType = (() => {
        switch (settings.picker) {
            case ColorSettingsPicker.PredefinedCustom:
                return ColorSelectionType.PredefinedColorList;
            case ColorSettingsPicker.Picker:
            default:
                return ColorSelectionType.FreeColorPicker;
        }
    })();

    /**
     * Get the color selection type options.
     */
    const colorSelectionTypeOptions = (() => {
        const newColorSelectionTypeOptions = {};
        Object.values(ColorSelectionType).forEach((key) => {
            newColorSelectionTypeOptions[key] = Translation.get(
                `dynamicLayers.layerEdit.editForms.forms.colorSettings.selectionType.${key}`,
                'template-designer'
            );
        });

        return newColorSelectionTypeOptions;
    })();

    if (!activeInput || settings.type !== 'color') return null;

    return (
        <div className="template-designer__dynamic-layer-edit__settings-form template-designer__dynamic-layer-edit__color-settings-form">
            {/* Color selection type */}
            <Tooltip title={Translation.get('dynamicLayers.layerEdit.editForms.forms.colorSettings.tooltip', 'template-designer')} disableInteractive>
                <InputWrapper gutterBottom>
                    <ToggleButtonGroup
                        value={colorSelectionType}
                        size="large"
                        fullWidth
                        color="primary"
                        onChange={(_, newColorSelectionType) => {
                            if (!newColorSelectionType || newColorSelectionType === colorSelectionType) return;
                            oldSettingsObject.current[colorSelectionType] = settings;

                            const oldSettings = oldSettingsObject.current?.[newColorSelectionType];
                            const newSettings = { ...selectionTypes[newColorSelectionType as ColorSelectionType], ...oldSettings, label: activeInput.label };
                            if (newColorSelectionType === ColorSelectionType.PredefinedColorList) {
                                newSettings.useGradients = false;
                            } else {
                                delete newSettings.colors;
                            }
                            updateActiveInput('settings', newSettings);
                        }}
                        exclusive>
                        {Object.keys(colorSelectionTypeOptions).map((key) => {
                            return (
                                <ToggleButton key={key} value={key}>
                                    {colorSelectionTypeOptions[key]}
                                </ToggleButton>
                            );
                        })}
                    </ToggleButtonGroup>
                </InputWrapper>
            </Tooltip>
            {/* Use gradients */}
            {canUseGradients && colorSelectionType === ColorSelectionType.FreeColorPicker && (
                <InputWrapper label={Translation.get('dynamicLayers.layerEdit.editForms.forms.colorSettings.useGradient', 'template-designer')}>
                    <Switch
                        checked={settings.useGradients}
                        onChange={(event) => {
                            updateInputSettings('useGradients', event.target.checked);
                            if (!event.target.checked) {
                                // Filter out gradient colors if they are disabled.
                                const newColors = cloneDeep(settings.colors)?.filter(
                                    (color) => !(typeof color === 'object' && color.type !== ColorOptions.Solid && color.type !== ColorOptions.Transparent)
                                );
                                updateInputSettings('colors', newColors);
                            }
                        }}
                    />
                </InputWrapper>
            )}
            {/* Use opacity */}
            {canUseAlpha && colorSelectionType === ColorSelectionType.FreeColorPicker && (
                <InputWrapper label={Translation.get('dynamicLayers.layerEdit.editForms.forms.colorSettings.useAlpha', 'template-designer')}>
                    <Switch
                        checked={settings.useAlpha}
                        onChange={(event) => {
                            updateInputSettings('useAlpha', event.target.checked);
                            if (!event.target.checked) {
                                // Filter out opacity colors if they are disabled.
                                const newColors = cloneDeep(settings.colors)?.filter(
                                    (color) => !(typeof color === 'object' && color.type !== ColorOptions.Solid && color.type !== ColorOptions.Transparent)
                                );
                                updateInputSettings('colors', newColors);
                            }
                        }}
                    />
                </InputWrapper>
            )}
            {/* Preset colors */}
            {colorSelectionType === ColorSelectionType.PredefinedColorList && (
                <FieldSetDynamicColorList
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.colorSettings.predefinedColors', 'template-designer')}
                    value={settings.colors}
                    onMutation={(colors) => {
                        const newColors = colors.map((color) => (typeof color === 'string' ? color : GlobalColorHelpers.getCssColor(color)));
                        updateInputSettings('colors', newColors);
                    }}
                    disableFirst
                    useGradients={canUseGradients}
                    useAlpha={canUseAlpha && settings.useAlpha}
                    colorPickerSize="medium"
                    presetColors={ColorHelpers.getPresetColors()}
                />
            )}
        </div>
    );
};

export { ColorSettingsForm };
