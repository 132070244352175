import React, { useEffect, useMemo, useState } from 'react';
import Translation from 'components/data/Translation';
import { AssetGalleryInputSettings } from 'components/template-designer/types/dynamicLayer.type';
import { useDynamicLayers } from 'components/template-designer/components/dynamic-layers/contexts/use-dynamic-layers';
import { ExpandSection } from 'components/template-designer/components/ui-components/expand-section';
import { PopupPanel } from 'components/template-designer/components/ui-components/popup-panel';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import AlertBox from 'components/input/AlertBox';
import ToggleButtonGroup from 'components/ui-components-v2/ToggleGroup';
import ToggleButton from 'components/ui-components-v2/ToggleButton';
import Alert from 'components/ui-components-v2/Alert';
import Setup from 'components/data/Setup';
import User from 'components/data/User';
import { InterfaceSetupHelpers } from 'components/template-designer/helpers/interface-setup.helpers';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import TextField from 'components/ui-components-v2/TextField';
import InputWrapper from 'components/ui-components-cape/InputWrapper';
import Select from 'components/ui-components-v2/Select';
import MenuItem from 'components/ui-components-v2/MenuItem';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import { View } from 'components/template-designer/types/template.type';
import { SwitchSetting } from '../inputs/switch-setting';
import { CheckboxSetting } from '../inputs/checkbox-setting';
import { ExtraSettingsButton } from '../inputs/extra-settings-button';
import { CollectionSetting } from '../inputs/collection-setting';
import '../styles/main.scss';

enum PopUpPanels {
    AssetLibrary = 'assetLibrary',
    Cropper = 'cropper',
    Transform = 'transform',
    Aprimo = 'aprimo',
    Compression = 'compression'
}

const ImageSettingsForm = (): JSX.Element | null => {
    const { frameType } = useComponentStore<{ frameType: View['frameType'] }>('TemplateDesigner', {
        fields: {
            frameType: 'view.frameType'
        }
    });

    const [popUpOpen, setPopUpOpen] = useState<PopUpPanels | null>(null);

    const { settings, activeInput, updateInputSettings } = useDynamicLayers<AssetGalleryInputSettings>();

    const inputHasBackgroundImage = useMemo(
        () =>
            settings.isBackgroundImage
                ? false
                : InterfaceSetupHelpers.hasBackgroundImage(getTemplateData(`dynamicLayers.${getTemplateData('view.frameType')}`)),
        [activeInput]
    );

    /**
     * Close the popup if the active input changes.
     */
    useEffect(() => {
        if (activeInput && settings.type === 'assetGalleryInput') return;
        setPopUpOpen(null);
    }, [activeInput, settings]);

    if (!activeInput || settings.type !== 'assetGalleryInput') return null;

    return (
        <div className="template-designer__dynamic-layer-edit__media-settings-form">
            <ExpandSection
                expandSectionKey="inputSources"
                disabled
                size="medium"
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.title', 'template-designer')}
                defaultOpen>
                {!settings.canUpload && !settings.canUseContentSpace && !settings.canUseUnsplash && !settings.canUseUrl && !settings.canUseAiContent && (
                    <Alert severity={'warning'}>
                        {Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.warning', 'template-designer')}
                    </Alert>
                )}
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.freeUpload', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.freeUploadImageTooltip', 'template-designer')}
                    icon="upload"
                    value={settings.canUpload}
                    update={(newValue) => updateInputSettings('canUpload', newValue)}
                />
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrary', 'template-designer')}
                    tooltip={Translation.get(
                        'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibraryImageTooltip',
                        'template-designer'
                    )}
                    icon="folder_open"
                    value={settings.canUseContentSpace}
                    update={(newValue) => updateInputSettings('canUseContentSpace', newValue)}>
                    <PopupPanel
                        width={350}
                        open={popUpOpen === PopUpPanels.AssetLibrary}
                        placement="left-start"
                        title={Translation.get(
                            'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.title',
                            'template-designer'
                        )}
                        button={<ExtraSettingsButton onClick={() => setPopUpOpen(PopUpPanels.AssetLibrary)} disabled={!settings.canUseContentSpace} />}>
                        {Setup.hasModule('mediaManagement') &&
                            User.hasRight(['assetManagerMediaWrite', 'assetManagerMediaRead', 'assetManagerMediaManagement']) && (
                                <CollectionSetting
                                    value={settings.contentSpaceCollectionQuery}
                                    onMutation={(newValue) => updateInputSettings('contentSpaceCollectionQuery', newValue, { useDebounce: true })}
                                    inputSize="medium"
                                />
                            )}
                        {Setup.hasModule('assetLibrary') && (
                            <InputWrapper
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionName',
                                    'template-designer'
                                )}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.assetLibrarySettings.collectionNameTooltip',
                                    'template-designer'
                                )}>
                                <TextField
                                    size="medium"
                                    value={settings.contentSpaceCollectionQuery}
                                    onChange={(event) => updateInputSettings('contentSpaceCollectionQuery', event.target.value, { useDebounce: true })}
                                />
                            </InputWrapper>
                        )}
                    </PopupPanel>
                </SwitchSetting>
                {DynamicLayerHelpers.hasUnsplash() && (
                    <SwitchSetting
                        label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.unsplash', 'template-designer')}
                        tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.unsplashTooltip', 'template-designer')}
                        icon="dashboard"
                        value={settings.canUseUnsplash}
                        update={(newValue) => updateInputSettings('canUseUnsplash', newValue)}
                    />
                )}
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.url', 'template-designer')}
                    tooltip={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.urlImageTooltip', 'template-designer')}
                    icon="link"
                    value={settings.canUseUrl}
                    update={(newValue) => updateInputSettings('canUseUrl', newValue)}
                />
                {DynamicLayerHelpers.hasAI() && (
                    <SwitchSetting
                        label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.aiImage', 'template-designer')}
                        icon="auto_awesome"
                        value={settings.canUseAiContent}
                        update={(newValue) => updateInputSettings('canUseAiContent', newValue)}
                        switchColor="secondary"
                    />
                )}
                {DynamicLayerHelpers.hasAprimo() && (
                    <SwitchSetting
                        label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.aprimo', 'template-designer')}
                        icon="cloud_download"
                        value={settings.canUseArimo}
                        update={(newValue) => updateInputSettings('canUseArimo', newValue)}>
                        <PopupPanel
                            width={350}
                            open={popUpOpen === PopUpPanels.Aprimo}
                            placement="left-start"
                            title={Translation.get(
                                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.aprimoSettings.title',
                                'template-designer'
                            )}
                            button={<ExtraSettingsButton onClick={() => setPopUpOpen(PopUpPanels.Aprimo)} disabled={!settings.canUseArimo} />}>
                            <InputWrapper
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.aprimoSettings.tenant',
                                    'template-designer'
                                )}>
                                <TextField
                                    size="medium"
                                    fullWidth
                                    value={settings.aprimoTenant}
                                    onChange={(event) => updateInputSettings('aprimoTenant', event.target.value, { useDebounce: true })}
                                />
                            </InputWrapper>
                            <InputWrapper
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.inputSources.aprimoSettings.outputFormat',
                                    'template-designer'
                                )}>
                                <Select
                                    size="medium"
                                    fullWidth
                                    value={settings.aprimoOutputFormat}
                                    onChange={(event) =>
                                        updateInputSettings('aprimoOutputFormat', event.target.value as AssetGalleryInputSettings['aprimoOutputFormat'], {
                                            useDebounce: false
                                        })
                                    }>
                                    <MenuItem value="jpg">JPG</MenuItem>
                                    <MenuItem value="png">PNG</MenuItem>
                                </Select>
                            </InputWrapper>
                        </PopupPanel>
                    </SwitchSetting>
                )}
            </ExpandSection>

            <ExpandSection
                expandSectionKey="mediaManipulation"
                disabled
                size="medium"
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.imageTitle', 'template-designer')}
                defaultOpen>
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.imageCropper', 'template-designer')}
                    icon="crop"
                    value={settings.useCropper}
                    update={(newValue) => updateInputSettings('useCropper', newValue)}>
                    <PopupPanel
                        width={350}
                        height={320}
                        open={popUpOpen === PopUpPanels.Cropper}
                        placement="left-start"
                        title={Translation.get(
                            'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.title',
                            'template-designer'
                        )}
                        button={<ExtraSettingsButton onClick={() => setPopUpOpen(PopUpPanels.Cropper)} disabled={!settings.useCropper} />}>
                        <ToggleButtonGroup
                            value={(() => {
                                if (
                                    settings.outputWidth === undefined &&
                                    settings.outputHeight === undefined &&
                                    settings.maxOutputWidth === undefined &&
                                    settings.maxOutputHeight === undefined
                                )
                                    return 'default';
                                return settings.mode;
                            })()}
                            size="medium"
                            color="primary"
                            fullWidth
                            onChange={(_, newValue) => {
                                if (!newValue) return;

                                if (newValue === 'default') {
                                    updateInputSettings('outputWidth', undefined);
                                    updateInputSettings('outputHeight', undefined);
                                    updateInputSettings('maxOutputWidth', undefined);
                                    updateInputSettings('maxOutputHeight', undefined);
                                } else if (newValue === 'free') {
                                    updateInputSettings('mode', newValue);
                                    updateInputSettings('maxOutputWidth', 100);
                                    updateInputSettings('maxOutputHeight', 100);
                                    updateInputSettings('outputWidth', undefined);
                                    updateInputSettings('outputHeight', undefined);
                                } else if (newValue === 'fixed') {
                                    updateInputSettings('mode', newValue);
                                    updateInputSettings('outputWidth', 100);
                                    updateInputSettings('outputHeight', 100);
                                    updateInputSettings('maxOutputWidth', undefined);
                                    updateInputSettings('maxOutputHeight', undefined);
                                }
                            }}
                            exclusive>
                            <ToggleButton value="free">
                                {Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.max',
                                    'template-designer'
                                )}
                            </ToggleButton>
                            <ToggleButton value="fixed">
                                {Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.fixed',
                                    'template-designer'
                                )}
                            </ToggleButton>
                            <ToggleButton value="default">
                                {Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.free',
                                    'template-designer'
                                )}
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <AlertBox
                            message={(() => {
                                if (settings.mode === 'free' && settings.maxOutputWidth !== undefined && settings.maxOutputHeight !== undefined) {
                                    return Translation.get(
                                        'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.freeTooltip',
                                        'template-designer'
                                    );
                                }

                                if (settings.mode === 'fixed' && settings.outputWidth !== undefined && settings.outputHeight !== undefined) {
                                    return Translation.get(
                                        'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.fixedTooltip',
                                        'template-designer'
                                    );
                                }

                                return Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.maxTooltip',
                                    'template-designer'
                                );
                            })()}
                        />

                        {settings.mode === 'fixed' && settings.outputWidth !== undefined && settings.outputHeight !== undefined && (
                            <>
                                <div className="template-designer__dynamic-layer-edit__media-settings-form__setting">
                                    <TextField
                                        size="medium"
                                        label={Translation.get('sizes.fixedWidth', 'common')}
                                        value={settings.outputWidth.toString()}
                                        onChange={(event) =>
                                            updateInputSettings(
                                                'outputWidth',
                                                (() => {
                                                    const newValue = parseInt(event.target.value);
                                                    if (isNaN(newValue)) return 0;
                                                    return newValue;
                                                })(),
                                                { useDebounce: true }
                                            )
                                        }
                                        slotProps={{
                                            input: {
                                                endAdornment: 'px'
                                            }
                                        }}
                                    />
                                    <TextField
                                        size="medium"
                                        label={Translation.get('sizes.fixedHeight', 'common')}
                                        value={settings.outputHeight.toString()}
                                        onChange={(event) =>
                                            updateInputSettings(
                                                'outputHeight',
                                                (() => {
                                                    const newValue = parseInt(event.target.value);
                                                    if (isNaN(newValue)) return 0;
                                                    return newValue;
                                                })(),
                                                { useDebounce: true }
                                            )
                                        }
                                        slotProps={{
                                            input: {
                                                endAdornment: 'px'
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        )}
                        {settings.mode === 'free' && settings.maxOutputWidth !== undefined && settings.maxOutputHeight !== undefined && (
                            <>
                                <div className="template-designer__dynamic-layer-edit__media-settings-form__setting">
                                    <TextField
                                        size="medium"
                                        label={Translation.get('sizes.maxWidth', 'common')}
                                        value={settings.maxOutputWidth.toString()}
                                        onChange={(event) =>
                                            updateInputSettings(
                                                'maxOutputWidth',
                                                (() => {
                                                    const newValue = parseInt(event.target.value);
                                                    if (isNaN(newValue)) return 0;
                                                    return newValue;
                                                })(),
                                                { useDebounce: true }
                                            )
                                        }
                                        slotProps={{
                                            input: {
                                                endAdornment: 'px'
                                            }
                                        }}
                                    />
                                    <TextField
                                        size="medium"
                                        label={Translation.get('sizes.maxHeight', 'common')}
                                        value={settings.maxOutputHeight.toString()}
                                        onChange={(event) =>
                                            updateInputSettings(
                                                'maxOutputHeight',
                                                (() => {
                                                    const newValue = parseInt(event.target.value);
                                                    if (isNaN(newValue)) return 0;
                                                    return newValue;
                                                })(),
                                                { useDebounce: true }
                                            )
                                        }
                                        slotProps={{
                                            input: {
                                                endAdornment: 'px'
                                            }
                                        }}
                                    />
                                </div>
                            </>
                        )}

                        <CheckboxSetting
                            label={Translation.get(
                                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.croppingRequired',
                                'template-designer'
                            )}
                            tooltip={Translation.get(
                                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.croppingRequiredTooltip',
                                'template-designer'
                            )}
                            setting="canSkipCropper"
                            value={!settings.canSkipCropper}
                            update={(event) => updateInputSettings('canSkipCropper', !event.target.checked)}
                        />
                    </PopupPanel>
                </SwitchSetting>
                {frameType !== 'base' && (
                    <SwitchSetting
                        label={Translation.get(
                            'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.formatSizeCropper',
                            'template-designer'
                        )}
                        icon="image"
                        disabledTooltip={
                            (!settings.isBackgroundImage &&
                                !!inputHasBackgroundImage &&
                                Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.layerAlreadyUsesFormatSizeCropper',
                                    'template-designer',
                                    { inputLabel: (typeof inputHasBackgroundImage === 'object' && inputHasBackgroundImage.label) || '' }
                                )) ||
                            ''
                        }
                        tooltip={Translation.get(
                            'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.cropSettings.formatSizeCropperTooltip',
                            'template-designer'
                        )}
                        disabled={!settings.isBackgroundImage && !!inputHasBackgroundImage}
                        value={settings.isBackgroundImage}
                        update={(newValue) => updateInputSettings('isBackgroundImage', newValue)}
                    />
                )}
                {DynamicLayerHelpers.hasAI() && (
                    <SwitchSetting
                        label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransform', 'template-designer')}
                        icon="auto_fix_high"
                        value={settings.canRemoveBackground || settings.canRemoveObjects || settings.canMagicEdit || settings.canOutpaint}
                        update={(newValue) => {
                            updateInputSettings('canRemoveBackground', newValue);
                            updateInputSettings('canRemoveObjects', newValue);
                            updateInputSettings('canMagicEdit', newValue);
                            updateInputSettings('canOutpaint', newValue);
                        }}
                        switchColor="secondary">
                        <PopupPanel
                            width={350}
                            open={popUpOpen === PopUpPanels.Transform}
                            placement="left-start"
                            button={
                                <ExtraSettingsButton
                                    onClick={() => setPopUpOpen(PopUpPanels.Transform)}
                                    disabled={!settings.canRemoveBackground && !settings.canRemoveObjects && !settings.canMagicEdit && !settings.canOutpaint}
                                />
                            }
                            title={Translation.get(
                                'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.title',
                                'template-designer'
                            )}>
                            <CheckboxSetting
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.backgroundRemover',
                                    'template-designer'
                                )}
                                setting="canRemoveBackground"
                                value={settings.canRemoveBackground}
                                update={(event) => updateInputSettings('canRemoveBackground', event.target.checked)}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.backgroundRemoverTooltip',
                                    'template-designer'
                                )}
                            />
                            <CheckboxSetting
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.objectRemover',
                                    'template-designer'
                                )}
                                setting="canRemoveObjects"
                                value={settings.canRemoveObjects}
                                update={(event) => updateInputSettings('canRemoveObjects', event.target.checked)}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.objectRemoverTooltip',
                                    'template-designer'
                                )}
                            />
                            <CheckboxSetting
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.magicEdit',
                                    'template-designer'
                                )}
                                setting="canMagicEdit"
                                value={settings.canMagicEdit}
                                update={(event) => updateInputSettings('canMagicEdit', event.target.checked)}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.magicEditTooltip',
                                    'template-designer'
                                )}
                            />
                            <CheckboxSetting
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.imageExpand',
                                    'template-designer'
                                )}
                                setting="canOutpaint"
                                value={settings.canOutpaint}
                                update={(event) => updateInputSettings('canOutpaint', event.target.checked)}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.mediaManipulation.aiTransformSettings.imageExpandTooltip',
                                    'template-designer'
                                )}
                            />
                        </PopupPanel>
                    </SwitchSetting>
                )}
            </ExpandSection>

            <ExpandSection
                title={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.title', 'template-designer')}
                expandSectionKey="outputSettings"
                size="medium"
                condition={DynamicLayerHelpers.isCompresserEnabled()}
                disabled
                defaultOpen>
                <SwitchSetting
                    label={Translation.get('dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compression', 'template-designer')}
                    tooltip={Translation.get(
                        'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.tooltip',
                        'template-designer'
                    )}
                    icon="compare"
                    value={settings.useCompressor}
                    update={(newValue) => updateInputSettings('useCompressor', newValue)}>
                    <PopupPanel
                        width={350}
                        open={popUpOpen === PopUpPanels.Compression}
                        placement="left-start"
                        title={Translation.get(
                            'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.title',
                            'template-designer'
                        )}
                        button={<ExtraSettingsButton onClick={() => setPopUpOpen(PopUpPanels.Compression)} disabled={!settings.useCompressor} />}>
                        <ToggleButtonGroup
                            value={settings.useCustomCompressor ? 'custom' : 'auto'}
                            size="medium"
                            fullWidth
                            color="primary"
                            onChange={(_, newValue) => {
                                if (!newValue) return;

                                updateInputSettings('useCustomCompressor', newValue === 'custom');

                                if (newValue === 'auto') {
                                    updateInputSettings('canSkipCompressor', false);
                                }
                            }}
                            exclusive>
                            <ToggleButton value="custom">
                                {Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.custom',
                                    'template-designer'
                                )}
                            </ToggleButton>
                            <ToggleButton value="auto">
                                {Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.auto',
                                    'template-designer'
                                )}
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <AlertBox
                            message={
                                settings.useCustomCompressor
                                    ? Translation.get(
                                          'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.customInfo',
                                          'template-designer'
                                      )
                                    : Translation.get(
                                          'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.autoInfo',
                                          'template-designer'
                                      )
                            }
                        />
                        {settings.useCustomCompressor && (
                            <CheckboxSetting
                                label={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.canSkipCompressor',
                                    'template-designer'
                                )}
                                tooltip={Translation.get(
                                    'dynamicLayers.layerEdit.editForms.forms.mediaSettings.outputSettings.compressionSettings.canSkipCompressorTooltip',
                                    'template-designer'
                                )}
                                setting="canSkipCompressor"
                                value={!settings.canSkipCompressor}
                                update={(event) => updateInputSettings('canSkipCompressor', !event.target.checked)}
                            />
                        )}
                    </PopupPanel>
                </SwitchSetting>
            </ExpandSection>
        </div>
    );
};

export { ImageSettingsForm };
